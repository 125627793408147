import { getCookie } from "cookies-next/server";
import merge from "lodash/merge";
import { GetServerSideProps, PreviewData } from "next";
import { ParsedUrlQuery } from "querystring";

import { getUserLocationData } from "api/requests/getUserLocation";
import serverConfig from "config/server";
import { setDomainCountryCode } from "redux/action-creators/config";
import { LOCATION_GEO_LOAD, LocationLoadAction } from "redux/reducers/location";
import { getDomainCountryCode } from "redux/selectors/config";
import { getHasLoadedLocationCookie } from "redux/selectors/location";
import { wrapper } from "redux/wrapper";

import type { LeaflyAppProps as AppProps } from "pages/_app";

/**
 * Adds the redux store to the context argument of a `getServerSideProps` function.
 */
type AddStore<
  Store,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- `any` required for `extends` type argument
  T extends (...args: any[]) => any,
> = (context: Parameters<T>[0] & { store: Store }) => ReturnType<T>;

type ReduxStore = ReturnType<typeof wrapper.useWrappedStore>["store"];

/**
 * Creates a function that encapulates functionality that should be run for
 * every page in the Pages Router. The single optional argument is an async
 * function that has the same interface as any normal `getServerSideProps`
 * configuration, with the execption that the `context` object will be extended
 * with a `store` property for accessing Redux.
 *
 * The type arguments for this function are the same as those used in Next's
 * `GetServerSideProps` type: the first argument should be the props used by
 * the page (if any), the second argument is the page's URL parameters.
 */
function createGetServerSideProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- `any` required for `extends` type argument
  Props extends Record<string, any> = Record<never, never>,
  Params extends ParsedUrlQuery = ParsedUrlQuery,
  Preview extends PreviewData = PreviewData,
>(
  ...[fn]: keyof Props extends never
    ? [
        AddStore<
          ReduxStore,
          GetServerSideProps<Props & Partial<AppProps>, Params, Preview>
        >?,
      ]
    : [
        AddStore<
          ReduxStore,
          GetServerSideProps<Props & Partial<AppProps>, Params, Preview>
        >,
      ]
): GetServerSideProps<Props & AppProps, Params, Preview> {
  const getServerSidePropsWithReduxStore =
    wrapper.getServerSideProps as unknown as (
      callback: (
        store: ReduxStore,
      ) => AddStore<ReduxStore, GetServerSideProps<Props, Params, Preview>>,
    ) => GetServerSideProps<Props & AppProps, Params, Preview>;

  return getServerSidePropsWithReduxStore((store) => async (context) => {
    context.store ||= store;

    store.dispatch(setDomainCountryCode(context.req.headers));

    const countryCode = getDomainCountryCode(store.getState());
    const hasLoadedLocationFromCookie = getHasLoadedLocationCookie(
      store.getState(),
    );

    if (!hasLoadedLocationFromCookie) {
      const userLocationData = await getUserLocationData(countryCode, context);
      store.dispatch<LocationLoadAction>({
        location: userLocationData,
        type: LOCATION_GEO_LOAD,
      });
    }

    const appProps: AppProps = {
      datadog: {
        env: serverConfig.datadogEnvironment,
        version: serverConfig.datadogVersion,
      },
      isAppBannerCookieDismissed: Boolean(
        await getCookie("appBannerDismissed", context),
      ),
    };

    const pageProps = await fn?.(context);

    if (pageProps && ("notFound" in pageProps || "redirect" in pageProps)) {
      return pageProps;
    } else {
      return merge({ props: appProps }, pageProps);
    }
  });
}

export { createGetServerSideProps };
