export const redirectIfPageOne = (path: string, page?: string | string[]) => {
  const [pathname, queryString] = path.split("?");
  const params = new URLSearchParams(queryString);
  params.delete("page");

  const revisedQueryString = params.toString();

  if (page === "1") {
    return {
      destination: revisedQueryString
        ? `${pathname}?${revisedQueryString}`
        : `${pathname}`,
      statusCode: 301 as const,
    };
  } else {
    return null;
  }
};
